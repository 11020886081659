import React, { useState, useEffect } from 'react';
import { Layout as AntLayout, Dropdown, Button, Menu, Typography } from 'antd';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { UserOutlined, DashboardOutlined, CalendarOutlined, TeamOutlined, CarOutlined, ShopOutlined, DownOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../utils/supabaseClient';
import type { MenuProps } from 'antd';

const { Header, Content, Sider, Footer } = AntLayout;
const { Text } = Typography;

const Layout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user) {
        const { data: authUser } = await supabase.auth.getUser();
        if (authUser && authUser.user) {
          setUsername(authUser.user.user_metadata.display_name || authUser.user.email);
        }
      }
    };
  
    fetchUserDetails();
  }, [user]);

  const openDocketsWindow = () => {
  const docketsWindow = window.open('/dockets', 'Dockets', 'width=1024,height=768,menubar=no,toolbar=no,location=no,status=no');
  
  if (docketsWindow) {
    docketsWindow.onload = () => {
      // Force a repaint to ensure styles are applied correctly
      docketsWindow.document.body.style.display = 'none';
      void docketsWindow.document.body.offsetHeight; // Trigger a reflow
      docketsWindow.document.body.style.display = '';

      // Remove any unwanted elements that might be inherited
      const unwantedElements = docketsWindow.document.querySelectorAll('.ant-layout-sider, .ant-layout-header, .ant-layout-footer');
      unwantedElements.forEach(el => el.parentNode?.removeChild(el));
    };
  }
};

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  const menuItems: MenuProps['items'] = [
    { key: '/dashboard', icon: <DashboardOutlined />, label: 'Dashboard' },
    { key: '/planner', icon: <CalendarOutlined />, label: 'Daily Planner' },
    { key: '/customers', icon: <TeamOutlined />, label: 'Customers' },
    { key: '/products', icon: <ShopOutlined />, label: 'Products' },
    { key: '/drivers', icon: <UserOutlined />, label: 'Drivers' },
    { key: '/trucks', icon: <CarOutlined />, label: 'Trucks' },
    
  ];

  const userMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: 'logout',
      label: 'Logout',
      onClick: handleSignOut,
    },
  ];

  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      <Sider 
        collapsible 
        collapsed={collapsed} 
        onCollapse={setCollapsed}
        theme="dark"
        style={{ position: 'fixed', height: '100vh', left: 0, zIndex: 2 }}
      >
        <div className="logo" style={{ 
          height: 64, 
          margin: 16,
          background: 'rgba(255, 255, 255, 0.0)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img 
            src={collapsed ? "/files/logo/logosmllight.png" : "/files/logo/logolight.png"}
            alt="Logo" 
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={menuItems}
          onClick={({ key }) => key === 'dockets' ? openDocketsWindow() : navigate(key)}
        />
      </Sider>
      <AntLayout>
        <Header style={{ 
          background: '#fff', 
          padding: '0px', 
          height: '48px',
          lineHeight: '48px',
          borderBottom: '1px solid #d9d9d9',
          borderTop: '1px solid #d9d9d9',
          position: 'fixed',
          width: '100%',
          zIndex: 1
        }}>
          <div style={{ float: 'right' }}>
            {user && (
              <Dropdown menu={{ items: userMenuItems }} trigger={['click']}>
                <Button type="link" className="ant-dropdown-link">
                  <UserOutlined style={{ marginRight: 8 }} />
                  {username || 'User'} <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </Header>
        <Content style={{ 
          margin: '48px 0 48px', 
          overflow: 'auto', 
          height: 'calc(100vh - 96px)',
          marginLeft: collapsed ? 80 : 200,
          transition: 'margin-left 0.2s'
        }}>
          <div className="page-content" style={{ height: '100%' }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ 
          textAlign: 'center', 
          height: '48px',
          lineHeight: '47px',
          padding: 0,
          borderTop: '1px solid #d9d9d9',
          position: 'fixed',
          width: '100%',
          bottom: 0,
          zIndex: 1
        }}>
          <Text type="secondary">AllocateIt v0.3.1 © 2024 Redbay Digital</Text>
        </Footer>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;