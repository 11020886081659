import React, { useState, useEffect } from 'react';
import { Card, Typography, Form, Input, Select, InputNumber, Button, Table, message, Row, Col, Spin, Tag } from 'antd';
import { supabase } from '../../utils/supabaseClient';
import dayjs from 'dayjs';
import { FileTextOutlined, TruckOutlined } from '@ant-design/icons';
import ReactDOMServer from 'react-dom/server';
import { PrintTemplate } from '../PrintTemplate';

const { Title } = Typography;
const { Option } = Select;

// Define interfaces for our data structures
interface XbinTruck {
  id: string;
  registration: string;
  company: string;
  driver_name: string;
  fleet_number: string;
  tare: number;
  order_number: string;
}

interface Product {
  id: string;
  name: string;
  type: string | null;
  product_code: string;
  lot_number: string;
}

interface DailyDocket {
  docket_number: number;
  product: string;
  product_type: string | null;
  created_at: string;
  net_weight: number;
  is_xbin: boolean;
}

const XbinDocket: React.FC = () => {
  const [form] = Form.useForm();
  const [trucks, setTrucks] = useState<XbinTruck[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [dailyDockets, setDailyDockets] = useState<DailyDocket[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextDocketNumber, setNextDocketNumber] = useState<number>(0);
  const [dailyProgressive, setDailyProgressive] = useState<number>(0);

  useEffect(() => {
    fetchTrucks();
    fetchProducts();
    fetchNextDocketNumber();
    fetchDailyProgressive();
  }, []);

  // Fetch trucks from the database
  const fetchTrucks = async () => {
    try {
      const { data: truckData, error: truckError } = await supabase
        .from('xbin_trucks')
        .select('*');

      if (truckError) throw truckError;

      if (truckData) {
        setTrucks(truckData);
      }
    } catch (error) {
      console.error('Error fetching trucks:', error);
      message.error('Failed to fetch trucks');
    }
  };

  // Fetch products from the database
  const fetchProducts = async () => {
    const { data, error } = await supabase.from('products').select('*');
    if (error) {
      message.error('Failed to fetch products');
    } else {
      setProducts(data);
    }
  };

  // Get the next available docket number
  const fetchNextDocketNumber = async () => {
    try {
      const { data, error } = await supabase
        .from('pulse_dockets')
        .select('docket_number')
        .order('docket_number', { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;
      setNextDocketNumber((data?.docket_number || 0) + 1);
    } catch (error) {
      console.error('Error fetching next docket number:', error);
      message.error('Failed to fetch next docket number');
    }
  };

  // Fetch daily progressive total
  const fetchDailyProgressive = async () => {
    const today = dayjs().startOf('day').toISOString();
    const { data, error } = await supabase
      .from('pulse_dockets')
      .select('net_weight')
      .eq('is_xbin', true)
      .gte('created_at', today);

    if (error) {
      console.error('Error fetching daily progressive:', error);
      message.error('Failed to fetch daily progressive total');
    } else {
      const total = data.reduce((sum, docket) => sum + (docket.net_weight || 0), 0);
      setDailyProgressive(total);
    }
  };

  // Fetch daily dockets for a specific truck
  const fetchDailyDockets = async (registration: string) => {
    const { data, error } = await supabase
      .from('pulse_dockets')
      .select('docket_number, product_id, created_at, net_weight, is_xbin')
      .eq('fleet_number', registration)
      .gte('created_at', dayjs().startOf('day').toISOString())
      .order('created_at', { ascending: false });

    if (error) {
      message.error('Failed to fetch daily dockets');
    } else {
      const dailyDocketsWithProducts = await Promise.all(data.map(async (docket) => {
        const { data: productData } = await supabase
          .from('products')
          .select('name, type')
          .eq('id', docket.product_id)
          .single();
        return {
          ...docket,
          product: productData?.name || 'Unknown',
          product_type: productData?.type || null,
        };
      }));
      setDailyDockets(dailyDocketsWithProducts);
    }
  };

  // Handle truck selection
  const onTruckSelect = async (value: string) => {
    const selectedTruck = trucks.find(truck => truck.registration === value);
    if (selectedTruck) {
      form.setFieldsValue({
        customer: selectedTruck.company,
        tare_weight: selectedTruck.tare,
        order_number: '', // Clear the order number field
      });

      fetchDailyDockets(selectedTruck.registration);
    }
  };

  // Handle form submission
  const onFinish = async (values: any) => {
  setLoading(true);
  try {
    const selectedTruck = trucks.find(truck => truck.registration === values.truck_registration);
    if (!selectedTruck) {
      throw new Error('Selected truck not found');
    }

    console.log('Selected Truck:', selectedTruck); // For debugging

    // Calculate new daily progressive
    const newDailyProgressive = dailyProgressive + values.net_weight;

    // Create new xbin order
    const newXbinJobNumber = `xb-${nextDocketNumber.toString().padStart(5, '0')}`;
    const { data: newXbinOrder, error: xbinOrderError } = await supabase
      .from('xbin_orders')
      .insert({
        order_number: values.order_number,
        xbin_truck_id: selectedTruck.id,
        xbin_job_number: newXbinJobNumber,
      })
      .single();

    if (xbinOrderError) throw xbinOrderError;

    console.log('New xBin Order:', newXbinOrder); // For debugging

    // Create the new pulse docket
    const xbinDocket = {
      docket_number: nextDocketNumber,
      truck_id: selectedTruck.id,
      delivery_address: 'xBin',
      product_id: values.product,
      quantity: values.net_weight,
      gross_weight: values.gross_weight,
      tare_weight: values.tare_weight,
      net_weight: values.net_weight,
      is_xbin: true,
      fleet_number: selectedTruck.registration,
      order_number: values.order_number,
      progressive: newDailyProgressive,
      xbin_job_number: newXbinJobNumber,
    };

    const { error: docketError } = await supabase
      .from('pulse_dockets')
      .insert(xbinDocket);

    if (docketError) throw docketError;

    message.success('xBin docket created successfully');
    setDailyProgressive(newDailyProgressive);
      const selectedProduct = products.find(p => p.id === values.product);
      if (!selectedProduct) {
        throw new Error('Selected product not found');
      }

      printXbinDocket({...xbinDocket, customer: selectedTruck.company}, selectedProduct);
      form.resetFields();
      fetchDailyDockets(selectedTruck.registration);
      fetchNextDocketNumber();
      setDailyProgressive(newDailyProgressive);
    } catch (error) {
      console.error('Error creating xBin docket:', error);
      message.error('Failed to create xBin docket');
    } finally {
      setLoading(false);
    }
  };

  // Print the docket
  const printXbinDocket = async (docketData: any, productData: any) => {
  const printContent = ReactDOMServer.renderToString(
    <PrintTemplate
      docketData={{
        docket_number: docketData.docket_number,
        plant: docketData.plant,
        customer: docketData.customer,
        customer_number: docketData.customer_number,
        delivery_address: 'xBin',
        order_number: docketData.order_number,
        product_name: productData.name,
        product_type: productData.type,
        product_code: productData.product_code,
        lot_number: productData.lot_number,
        xbin_job_number: docketData.xbin_job_number,
        fleet_number: docketData.fleet_number,
        progressive: docketData.progressive,
        gross_weight: docketData.gross_weight,
        tare_weight: docketData.tare_weight,
        net_weight: docketData.net_weight
      }}
      type="xbinDocket"
    />
  );

  console.log('Print content generated');

  try {
    const electronAPI = (window as any).electronAPI || (window.opener && window.opener.electronAPI);
    if (electronAPI && electronAPI.printDocket) {
      console.log('Sending print request to Electron');
      const result = await electronAPI.printDocket(printContent);
      console.log('Print result:', JSON.stringify(result, null, 2));
      if (result.success) {
        message.success('Docket sent to printer');
      } else {
        throw new Error(result.error || 'Unknown printing error');
      }
    } else {
      throw new Error('Electron API not available');
    }
  } catch (error) {
    console.error('Error printing docket:', error);
    message.error(`Failed to print docket: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};
  
  
  // Define columns for the daily dockets table
  const columns = [
    { 
      title: 'Docket Number', 
      dataIndex: 'docket_number', 
      key: 'docket_number',
      render: (text: number) => text.toString().padStart(2, '0')
    },
    { 
      title: 'Product', 
      dataIndex: 'product', 
      key: 'product',
      render: (text: string, record: DailyDocket) => `${text} ${record.product_type ? `(${record.product_type})` : ''}`
    },
    { 
      title: 'Date', 
      dataIndex: 'created_at', 
      key: 'created_at',
      render: (text: string) => dayjs(text).format('DD-MM-YYYY HH:mm:ss')
    },
    { title: 'Net Weight', dataIndex: 'net_weight', key: 'net_weight' },
  ];

  return (
    <div className="xbin-docket-container" style={{ padding: '24px' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={24}>
          <Col span={12}>
            <Card
              title={<Title level={4}><TruckOutlined /> xBin Docket</Title>}
              extra={<Tag color="blue">Docket #{nextDocketNumber}</Tag>}
              style={{ marginBottom: 20 }}
            >
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="truck_registration" label="Registration #" rules={[{ required: true }]}>
                      <Select onSelect={onTruckSelect} showSearch>
                        {trucks.map(truck => (
                          <Option key={truck.id} value={truck.registration}>{truck.registration}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="customer" label="Customer">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="product" label="Product" rules={[{ required: true }]}>
                      <Select showSearch>
                        {products.map(product => (
                          <Option key={product.id} value={product.id}>{`${product.name} ${product.type ? `(${product.type})` : ''}`}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="order_number" label="Order Number" rules={[{ required: true }]}>
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item name="tare_weight" label="Tare Weight">
                      <Input style={{ width: '100%' }} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="gross_weight" label="Gross Weight" rules={[{ required: true }]}>
                      <InputNumber
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          const tare = form.getFieldValue('tare_weight');
                          const net = value && tare ? Number(value) - Number(tare) : null;
                          form.setFieldsValue({ net_weight: net });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="net_weight" label="Net Weight">
                      <Input style={{ width: '100%' }} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>Create xBin Docket</Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={<Title level={4}><FileTextOutlined /> Daily Dockets</Title>}
              style={{ marginBottom: 20 }}
            >
              <Table columns={columns} dataSource={dailyDockets} rowKey="docket_number" />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default XbinDocket;
