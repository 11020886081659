import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, Typography, Popconfirm, Row, Col, Tag, Space, InputNumber } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone, TruckOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/es/table';
import { Key } from 'antd/es/table/interface';
import { supabase } from '../utils/supabaseClient';

const { Title } = Typography;
const { Option } = Select;

interface Truck {
  id: string;
  fleet_number: string;
  type: string;
  status: string;
  driver_name: string | null;
  notes: string;
  truck_rego: string;
  trailer_rego: string;
  date_added: string;
  current_job: string;
  location_id: string;
  body_tare: number;
  trailer_tare: number;
  tare: number;
}

interface Driver {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

const Trucks: React.FC = () => {
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [allTrucks, setAllTrucks] = useState<Truck[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingTruck, setEditingTruck] = useState<Truck | null>(null);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Truck>>({});
  const [currentTruckType, setCurrentTruckType] = useState<string>('');

  useEffect(() => {
    fetchTrucks();
    fetchDrivers();
    fetchLocations();
  }, []);

  const fetchTrucks = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('trucks')
      .select('*');
    if (error) {
      message.error('Failed to fetch trucks');
    } else {
      setTrucks(data || []);
      setAllTrucks(data || []);
    }
    setLoading(false);
  };

  const fetchDrivers = async () => {
    const { data, error } = await supabase
      .from('drivers')
      .select('id, name');
    if (error) {
      message.error('Failed to fetch drivers');
    } else {
      setDrivers(data || []);
    }
  };

  const fetchLocations = async () => {
    const { data, error } = await supabase
      .from('locations')
      .select('id, name');
    if (error) {
      message.error('Failed to fetch locations');
    } else {
      setLocations(data || []);
    }
  };

  const handleSearch = (value: string) => {
    const filteredData = allTrucks.filter((truck) =>
      Object.values(truck).some((val) =>
        val?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setTrucks(filteredData);
  };

  const handleAddTruck = () => {
    setEditingTruck(null);
    form.resetFields();
    setCurrentTruckType('');
    setModalVisible(true);
  };

  const handleEditTruck = (truck: Truck) => {
    setEditingTruck(truck);
    setCurrentTruckType(truck.type);
    form.setFieldsValue({
      ...truck,
      driver_name: truck.driver_name || undefined
    });
    setModalVisible(true);
  };

  const handleDeleteTruck = async (id: string) => {
    try {
      const { error } = await supabase
        .from('trucks')
        .delete()
        .eq('id', id);
      if (error) throw error;
      message.success('Truck deleted successfully');
      fetchTrucks();
    } catch (error) {
      console.error('Error deleting truck:', error);
      message.error('Failed to delete truck');
    }
  };

  const checkFleetNumberUniqueness = async (fleetNumber: string) => {
    const { data, error } = await supabase
      .from('trucks')
      .select('id')
      .eq('fleet_number', fleetNumber);
    
    if (error) {
      console.error('Error checking fleet number uniqueness:', error);
      return false;
    }

    if (editingTruck) {
      return data.length === 0 || (data.length === 1 && data[0].id === editingTruck.id);
    } else {
      return data.length === 0;
    }
  };

  const handleSaveTruck = async (values: any) => {
    try {
      if (!/^\d{4}$/.test(values.fleet_number)) {
        message.error('Fleet number must be exactly 4 digits');
        return;
      }

      const isUnique = await checkFleetNumberUniqueness(values.fleet_number);
      if (!isUnique) {
        message.error('Fleet number is already in use');
        return;
      }

      const formattedValues = {
        ...values,
        tare: ['Truck and Dog', 'Semi Tipper'].includes(values.type) ? (values.body_tare || 0) + (values.trailer_tare || 0) : values.body_tare,
        trailer_tare: ['Truck and Dog', 'Semi Tipper'].includes(values.type) ? values.trailer_tare : null,
        trailer_rego: ['Truck and Dog', 'Semi Tipper'].includes(values.type) ? values.trailer_rego : null,
      };

      if (editingTruck) {
        const { error } = await supabase
          .from('trucks')
          .update(formattedValues)
          .eq('id', editingTruck.id);

        if (error) throw error;
        message.success('Truck updated successfully');
      } else {
        const { error } = await supabase
          .from('trucks')
          .insert(formattedValues);

        if (error) throw error;
        message.success('Truck added successfully');
      }

      setModalVisible(false);
      form.resetFields();
      fetchTrucks();
    } catch (error) {
      console.error('Error saving truck:', error);
      message.error('Failed to save truck');
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Truck> | SorterResult<Truck>[]
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(Array.isArray(sorter) ? sorter[0] : sorter);
  };

  const getTruckColor = (type: string): string => {
    switch (type) {
      case 'Truck and Dog': return 'blue';
      case 'Body Truck': return 'green';
      case 'Semi Tipper': return 'orange';
      case '8 Wheeler': return 'purple';
      case '10 Wheeler': return 'red';
      default: return 'default';
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'active': return 'green';
      case 'inactive': return 'red';
      case 'spare': return 'blue';
      case 'maintenance': return 'orange';
      case 'for sale': return 'grey';
      default: return 'default';
    }
  };

  const columns: ColumnsType<Truck> = [
    {
      title: 'Fleet #',
      dataIndex: 'fleet_number',
      key: 'fleet_number',
      sorter: (a: Truck, b: Truck) => Number(a.fleet_number) - Number(b.fleet_number),
      sortOrder: sortedInfo.columnKey === 'fleet_number' ? sortedInfo.order : 'descend',
      defaultSortOrder: 'descend',
      ellipsis: true,
      render: (text: string, record: Truck) => (
        <Tag color={getTruckColor(record.type)}>{text}</Tag>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Truck and Dog', value: 'Truck and Dog' },
        { text: 'Body Truck', value: 'Body Truck' },
        { text: 'Semi Tipper', value: 'Semi Tipper' },
        { text: '8 Wheeler', value: '8 Wheeler' },
        { text: '10 Wheeler', value: '10 Wheeler' },
      ],
      filteredValue: filteredInfo.type || null,
      onFilter: (value: boolean | Key, record: Truck) => record.type.includes(String(value)),
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Inactive', value: 'Inactive' },
        { text: 'Spare', value: 'Spare' },
        { text: 'Maintenance', value: 'Maintenance' },
        { text: 'For Sale', value: 'For Sale' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value: boolean | Key, record: Truck) => record.status === String(value),
      render: (status: string) => (
        <Tag color={getStatusColor(status)} style={{ minWidth: '80px', textAlign: 'center' }}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Driver Name',
      dataIndex: 'driver_name',
      key: 'driver_name',
      render: (driver_name: string | null) => driver_name || 'Not Assigned',
    },
    {
      title: 'Truck Rego',
      dataIndex: 'truck_rego',
      key: 'truck_rego',
    },
    {
    title: 'Trailer Rego',
    dataIndex: 'trailer_rego',
    key: 'trailer_rego',
  },
  {
    title: 'Location',
    dataIndex: 'location_id',
    key: 'location',
    filters: locations.map(loc => ({ text: loc.name, value: loc.id })),
    filteredValue: filteredInfo.location_id || null,
    onFilter: (value: boolean | Key, record: Truck) => record.location_id === String(value),
    render: (locationId: string) => locations.find(loc => loc.id === locationId)?.name || 'N/A',
  },
  {
    title: 'Tare (t)',
    dataIndex: 'tare',
    key: 'tare',
    sorter: (a, b) => (a.tare || 0) - (b.tare || 0),
    render: (tare: number | null) => tare !== null ? tare.toFixed(2) : 'N/A',
  },
  {
    title: 'Actions',
    key: 'actions',
      render: (_: any, record: Truck) => (
        <Space>
          <Button icon={<EditTwoTone />} onClick={() => handleEditTruck(record)} />
          <Popconfirm
            title="Are you sure you want to delete this truck?"
            onConfirm={() => handleDeleteTruck(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteTwoTone twoToneColor="#ff4d4f" />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="content-container">
      <div style={{ marginBottom: 16 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Trucks</Title>
          </Col>
          <Col>
            <Space>
              <Input.Search
                placeholder="Search trucks"
                onSearch={handleSearch}
                style={{ width: 200 }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddTruck}
              >
                Add Truck
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <Table
        columns={columns}
        dataSource={trucks}
        rowKey="id"
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title={<Title level={3}><TruckOutlined /> {editingTruck ? "Edit Truck" : "Add New Truck"}</Title>}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingTruck(null);
          form.resetFields();
          setCurrentTruckType('');
        }}
        footer={null}
        width={720}
      >
        <Form form={form} onFinish={handleSaveTruck} layout="vertical">
  <Row gutter={16}>
    <Col span={12}>
      <Form.Item
        name="fleet_number"
        label="Fleet #"
        rules={[
          { required: true, message: 'Please input the fleet number!' },
          { pattern: /^\d{4}$/, message: 'Fleet number must be exactly 4 digits' },
          {
            validator: async (_, value) => {
              if (value && !/^\d{4}$/.test(value)) {
                return Promise.reject('Fleet number must be exactly 4 digits');
              }
              const isUnique = await checkFleetNumberUniqueness(value);
              if (!isUnique) {
                return Promise.reject('Fleet number is already in use');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item name="location_id" label="Location" rules={[{ required: true }]}>
        <Select
          allowClear
          onSelect={() => {
            (document.activeElement as HTMLElement).blur();
          }}
        >
          {locations.map(location => (
            <Option key={location.id} value={location.id}>{location.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col span={12}>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          allowClear
          onSelect={(value) => {
            setCurrentTruckType(value as string);
            form.setFieldsValue({
              trailer_tare: null,
              tare: null,
              trailer_rego: null
            });
            (document.activeElement as HTMLElement).blur();
          }}
        >
          <Option value="Truck and Dog">Truck and Dog</Option>
          <Option value="Body Truck">Body Truck</Option>
          <Option value="Semi Tipper">Semi Tipper</Option>
          <Option value="8 Wheeler">8 Wheeler</Option>
          <Option value="10 Wheeler">10 Wheeler</Option>
        </Select>
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Select
          allowClear
          onSelect={() => {
            (document.activeElement as HTMLElement).blur();
          }}
        >
          <Option value="Active">Active</Option>
          <Option value="Inactive">Inactive</Option>
          <Option value="Spare">Spare</Option>
          <Option value="Maintenance">Maintenance</Option>
          <Option value="For Sale">For Sale</Option>
        </Select>
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col span={12}>
      <Form.Item name="driver_name" label="Driver Name">
        <Select
          allowClear
          onSelect={() => {
            (document.activeElement as HTMLElement).blur();
          }}
        >
          <Option value="">Not Assigned</Option>
          {drivers.map(driver => (
            <Option key={driver.id} value={driver.name}>{driver.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item name="truck_rego" label="Truck Rego">
        <Input />
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col span={12}>
      <Form.Item 
        name="trailer_rego" 
        label="Trailer Rego"
        hidden={!['Truck and Dog', 'Semi Tipper'].includes(currentTruckType)}
      >
        <Input />
      </Form.Item>
    </Col>
  </Row>
  <Row gutter={16}>
    <Col span={8}>
      <Form.Item
        name="body_tare"
        label="Body Tare Weight (tonnes)"
        rules={[{ required: true, message: 'Please input the body tare weight!' }]}
      >
        <InputNumber 
          min={0} 
          step={0.01} 
          style={{ width: '100%' }} 
          onChange={(value) => {
            const bodyTare = value || 0;
            const trailerTare = form.getFieldValue('trailer_tare') || 0;
            form.setFieldsValue({ tare: bodyTare + trailerTare });
          }}
        />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        name="trailer_tare"
        label="Trailer Tare Weight (tonnes)"
        hidden={!['Truck and Dog', 'Semi Tipper'].includes(currentTruckType)}
      >
        <InputNumber 
          min={0} 
          step={0.01} 
          style={{ width: '100%' }} 
          onChange={(value) => {
            const bodyTare = form.getFieldValue('body_tare') || 0;
            const trailerTare = value || 0;
            form.setFieldsValue({ tare: bodyTare + trailerTare });
          }}
        />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        name="tare"
        label="Combined Tare Weight (tonnes)"
        hidden={!['Truck and Dog', 'Semi Tipper'].includes(currentTruckType)}
      >
        <InputNumber disabled style={{ width: '100%' }} />
      </Form.Item>
    </Col>
  </Row>
  <Form.Item name="notes" label="Notes">
    <Input.TextArea />
  </Form.Item>
  <Form.Item>
    <Button type="primary" htmlType="submit">
      {editingTruck ? 'Update Truck' : 'Add Truck'}
    </Button>
  </Form.Item>
</Form>
      </Modal>
    </div>
  );
};

export default Trucks;
